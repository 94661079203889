import appClient from '@shared/api/appClient';
import i18n from '@shared/i18n';

const COUNTDOWN_FROM = 90;

export default {
  namespaced: true,
  namespace: 'lesson',
  state: {
    lesson: null,
    module: null,
    courseId: null,
    activeTab: 2,
    defaultTab: 2,
    showUpcoming: false,
    nextVideoLoading: false,
    countdownRunning: true,
    countdown: {
      from: COUNTDOWN_FROM,
      seconds: COUNTDOWN_FROM,
      running: true,
      paused: false,
      timer: null,
    },
    lockedContentOrigin: null,
    previousTimestamp: null,
    ignoreProgress: false,
    annotationActive: null,
  },
  getters: {
    tabs: (state, getters, rootState) => {
      const isOverview = state.lesson.course ? state.lesson.course.locked : rootState.course.course.locked;

      return [
        ...(isOverview
          ? [
              {
                id: 0,
                name: i18n.global.t('tabs.courseOverview'),
                component: 'LessonCourseOverview',
                hideOnSidebar: false,
                isLocked: false,
              },
            ]
          : []),
        {
          id: 1,
          name: i18n.global.t('tabs.lessons'),
          component: 'Sidebar',
          hideOnSidebar: true,
          isLocked: false,
        },
        {
          id: 2,
          name: i18n.global.t('tabs.comments'),
          component: 'LessonComments',
          hideOnSidebar: false,
          isLocked: false,
        },
        // Hide assignments tab for course 15 (ICA)
        ...((state.lesson.assignments_html || rootState.user.guest) && state.lesson?.course?.id !== 15
          ? [
              {
                id: 3,
                name: i18n.global.t('tabs.project'),
                component: 'LessonProject',
                hideOnSidebar: false,
                isLocked: getters.tabsLocked,
              },
            ]
          : []),
        ...(getters.showNotes
          ? [
              {
                id: 4,
                name: i18n.global.t('tabs.notes'),
                component: 'LessonNotes',
                hideOnSidebar: false,
                isLocked: getters.tabsLocked,
              },
            ]
          : []),
        ...(state.lesson.files
          ? [
              {
                id: 5,
                name: i18n.global.t('tabs.files'),
                component: 'LessonFiles',
                hideOnSidebar: false,
                isLocked: getters.tabsLocked,
              },
            ]
          : []),
      ];
    },
    tabsLocked: (state, getters, rootState) => {
      return !state.lesson.is_teaser || rootState.user.guest;
    },
    showNotes: (state, getters, rootState) => {
      return (
        (state.lesson.notes && (state.lesson.notes.summary_notes || state.lesson.notes.notes)) || rootState.user.guest
      );
    },
    singleLesson: (state, getters, rootState) => (id) => {
      const course = rootState.course.course;

      let singleLesson = null;
      let singleModule = null;

      course?.modules.forEach((module) => {
        return module.lessons.find((lesson) => {
          if (lesson.id === id) {
            singleModule = module;
            singleLesson = lesson;
          }
          return;
        });
      });

      return {
        lesson: singleLesson,
        module: singleModule,
      };
    },
    progressPercent: (state) => {
      if (state.lesson) {
        const num = state.lesson.progress / state.lesson.duration;
        return +num.toFixed(2);
      }
    },
  },
  actions: {
    // Get lesson from the API
    fetchLesson({ commit }, id) {
      commit('RESET_LESSON');

      return appClient.getLesson(id).then(({ data }) => {
        if (data) {
          commit('FETCH_LESSON', data);
        }
      });
    },
    // Get shared lesson from the API
    fetchSharedLesson({ commit }, id) {
      commit('RESET_LESSON');

      return appClient.getSharedLesson(id).then(({ data }) => {
        if (data) {
          commit('FETCH_LESSON', data);
        }
      });
    },
    // Get lesson from store
    setLesson({ commit, getters }, id) {
      commit('RESET_LESSON');

      return new Promise((resolve) => {
        resolve();
      }).then(() => {
        commit('SET_LESSON', {
          getters: getters,
          lessonId: id,
        });
      });
    },
    updateLesson({ commit, getters }, id) {
      return new Promise((resolve) => {
        resolve();
      }).then(() => {
        commit('UPDATE_LESSON', {
          getters: getters,
          lessonId: parseInt(id),
        });
      });
    },
    resetLesson({ commit }) {
      commit('RESET_LESSON');
    },
    fetchLessonAdditionalFields({ state, commit }, id) {
      return appClient.getLesson(id).then(({ data }) => {
        if (data) {
          commit('UPDATE_LESSON_ADDITIONAL_FILEDS', data);
        }
      });
    },
    setProgress({ state, commit, dispatch, getters }, payload) {
      if (!state.lesson) {
        return;
      }

      return new Promise((resolve, reject) => {
        appClient
          .setProgress(state.lesson.lesson_revision_id, payload.lessonProgress)
          .then((response) => {
            if (!response.data) {
              return;
            }

            // Update lesson progress
            commit('SET_PROGRESS', {
              getters: getters,
              lessonId: state.lesson.id,
              lessonWatched: response.data.is_watched,
              lessonProgress: payload.lessonProgress,
              upsellCta: response.data.upsell_cta,
            });

            if (
              response.data.hasOwnProperty('module_percent_completed') ||
              response.data.hasOwnProperty('course_percent_completed')
            ) {
              // Update module progress
              dispatch(
                'course/updateProgress',
                {
                  moduleId: state.module.id,
                  moduleProgress: response.data.module_percent_completed,
                  courseProgress: response.data.course_percent_completed,
                },
                { root: true },
              );

              // Update course progress
              dispatch(
                'courses/updateProgress',
                {
                  courseId: state.courseId,
                  courseProgress: response.data.course_percent_completed,
                },
                { root: true },
              );
            }

            if (response.data.hasOwnProperty('current_lesson_id')) {
              // Update course current lesson
              dispatch(
                'courses/updateCurrentLesson',
                {
                  courseId: state.courseId,
                  currentLessonId: response.data.current_lesson_id,
                },
                { root: true },
              );
            }

            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    selectTab({ commit }, value) {
      commit('SELECT_TAB', value);
    },
    setShowUpcoming({ commit }, value) {
      commit('SET_SHOW_UPCOMING', value);
    },
    startCountdown({ commit }) {
      commit('START_COUNTDOWN');
    },
    toggleCountdown({ commit }, value) {
      commit('TOGGLE_COUNTDOWN', value);
    },
    pauseCountdown({ commit, dispatch }, value) {
      commit('PAUSE_COUNTDOWN', value);

      if (!value) {
        dispatch('resetCountdown');
      }
    },
    resetCountdown({ commit }) {
      commit('RESET_COUNTDOWN');
      commit('START_COUNTDOWN');
    },
    toggleNextVideoLoading({ commit }, value) {
      commit('TOGGLE_NEXT_VIDEO_LOADING', value);
    },
    setLockedContentOrigin({ commit }, value) {
      commit('SET_LOCKED_CONTENT_ORIGIN', value);
    },
    setPreviousTimestamp({ commit }, value) {
      commit('SET_PREVIOUS_TIMESTAMP', value);
    },
    setIgnoreProgress({ commit }, value) {
      commit('SET_IGNORE_PROGRESS', value);
    },
    toggleAnnotation({ commit }, value) {
      commit('TOGGLE_ANNOTATION', value);
    },
  },
  mutations: {
    FETCH_LESSON(state, value) {
      state.lesson = value;
      state.module = {
        title: value.module_title,
        subtitle: value.module_subtitle,
        id: value.module_id,
      };
      state.courseId = value.course.id;
    },
    SET_LESSON(state, { getters, lessonId }) {
      state.lesson = getters.singleLesson(lessonId).lesson;
      state.module = getters.singleLesson(lessonId).module;
    },
    UPDATE_LESSON(state, { getters, lessonId }) {
      state.lesson = getters.singleLesson(lessonId).lesson;
      state.module = getters.singleLesson(lessonId).module;
    },
    RESET_LESSON(state) {
      state.lesson = null;
      state.module = null;
    },
    UPDATE_LESSON_ADDITIONAL_FILEDS(state, fullLesson) {
      Object.keys(fullLesson).forEach((key) => {
        if (!state.lesson[key]) state.lesson[key] = fullLesson[key];
      });
    },
    SET_PROGRESS(state, { getters, lessonId, lessonWatched, lessonProgress, upsellCta }) {
      if (state.lesson) {
        getters.singleLesson(lessonId).lesson.progress = lessonProgress;

        if (state.lesson.id === lessonId) {
          state.lesson.progress = lessonProgress;
        }

        // Update upsell button if promition is triggered
        if (upsellCta) {
          state.lesson.upsell_cta = upsellCta;
        }

        // Set lesson watched state
        state.lesson.is_watched = lessonWatched;

        getters.singleLesson(lessonId).lesson.is_watched = lessonWatched;
      }
    },
    SELECT_TAB(state, tab) {
      state.activeTab = tab;
    },
    SET_SHOW_UPCOMING(state, value) {
      state.showUpcoming = value;
    },
    START_COUNTDOWN(state) {
      state.countdown.seconds = state.countdown.from;
      state.countdown.running = true;
      state.countdown.paused = false;

      if (state.countdown.timer) {
        clearInterval(state.countdown.timer);
      }

      state.countdown.timer = setInterval(() => {
        if (state.countdown.running && !state.countdown.paused) {
          state.countdown.seconds -= 1;
        }
      }, 100);
    },
    TOGGLE_COUNTDOWN(state, value) {
      state.countdown.running = value;
    },
    PAUSE_COUNTDOWN(state, value) {
      state.countdown.paused = value;
    },
    RESET_COUNTDOWN(state) {
      clearInterval(state.countdown.timer);
      state.countdown.seconds = state.countdown.from;
      state.countdown.paused = false;
      state.countdown.running = true;
    },
    TOGGLE_NEXT_VIDEO_LOADING(state, value) {
      state.nextVideoLoading = value;
    },
    SET_LOCKED_CONTENT_ORIGIN(state, value) {
      state.lockedContentOrigin = value;
    },
    SET_PREVIOUS_TIMESTAMP(state, value) {
      state.previousTimestamp = value;
    },
    SET_IGNORE_PROGRESS(state, value) {
      state.ignoreProgress = value;
    },
    TOGGLE_ANNOTATION(state, value) {
      state.annotationActive = value;
    },
  },
};
